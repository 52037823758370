import crypto from "crypto";
import { ALGORITHM, ALGORITHM_IV } from "../config/configs";
import { showErrorToast } from "../utils";

export const encryptme = (req) => {
  try {
    const cipher = crypto.createCipheriv(
      ALGORITHM,
      "(G+KbPeSgVkYp3s6v9y$B&E)H@McQfTj",
      ALGORITHM_IV
    );
    let encrypted = cipher.update(req, "utf8", "Base64");
    encrypted += cipher.final("Base64");
    return encrypted;
  } catch (err) {
    //showErrorToast("Internal error occured!");
  }
};

export const decryptme = (resp) => {
  try {
    if (resp) {
      const decipher = crypto.createDecipheriv(
        ALGORITHM,
        "(G+KbPeSgVkYp3s6v9y$B&E)H@McQfTj",
        ALGORITHM_IV
      );
      let decrypted = decipher.update(resp, "Base64", "utf8");
      decrypted += decipher.final("utf8");
      return decrypted;
    } else {
      return false;
    }
  } catch (err) {
    //showErrorToast("Internal error occured!");
  }
};
