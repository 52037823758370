import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { DashboardRoute, CmsPageRoutes } from "./utils/index";

const Layout = lazy(() => import("./components/layout"));

class AppRouting extends Component {
  requireAuth = () => {
    return localStorage.getItem("token") ? true : false;
  };

  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <div className="AppRouting">
            <Switch>
              <Suspense fallback={<div></div>}>
                <Route exact path={DashboardRoute.HOME} component={Layout} />
                <Route exact path={DashboardRoute.LANDING_SECOND} component={Layout} />
                <Route exact path={DashboardRoute.LANDING_THIRD} component={Layout} />
                <Route exact path={CmsPageRoutes.ABOUT_US} component={Layout} />
                <Route exact path={CmsPageRoutes.ABOUT_US_NEW} component={Layout} />
                <Route exact path={CmsPageRoutes.CARRIER} component={Layout} />
                <Route exact path={CmsPageRoutes.PRICING} component={Layout} />
                <Route exact path={CmsPageRoutes.PRIVACY_POLICY} component={Layout} />
                <Route exact path={CmsPageRoutes.SITEMAP} component={Layout} />
                <Route
                  exact
                  path={CmsPageRoutes.CONTACT_US}
                  component={Layout}
                />
              <Route exact path={CmsPageRoutes.ILIFE_QUOTE_ENGINE} component={Layout} />
              </Suspense>
            </Switch>
          </div>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default AppRouting;
