import React, { Component } from "react";
// import { UnderWriteRoute, CmsPageRoute } from './routes/index';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import AppRouting from "./AppRouting";
import $ from 'jquery'; 
// import { ToastContainer } from "react-toastify";
import "animate.css/animate.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./scss/styles.scss";
import "jquery-mousewheel";


require("dotenv").config();
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <div className="App">
            <AppRouting />
          </div>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
