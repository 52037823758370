const env = {
  production: {
    API_URL: "http://ilife-api.devpress.net/",
    API_URL_ILIFE: "http://ilife-api.devpress.net/ilifeService/",
    AGENT_PORTAL_URL: "http://ilife-agent.devpress.net",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
  },
  staging: {
    API_URL: "http://ilife-api.devpress.net/",
    API_URL_ILIFE: "http://ilife-api.devpress.net/ilifeService/",
    AGENT_PORTAL_URL: "http://ilife-agent.devpress.net",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
  },
  development: {
    API_URL: "http://ilife-api.devpress.net/",
    API_URL_ILIFE: "http://ilife-api.devpress.net/ilifeService/",
    AGENT_PORTAL_URL: "http://ilife-agent.devpress.net",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
  },
  local: {
    API_URL: "http://ilife-api.devpress.net/",
    API_URL_ILIFE: "http://ilife-api.devpress.net/ilifeService/",
    AGENT_PORTAL_URL: "http://ilife-agent.devpress.net",
    PORT: 3000,
    ALGORITHM: process.env.REACT_APP_ALGORITHM,
    ALGORITHM_KEY: process.env.REACT_APP_ALGORITHM_KEY,
    ALGORITHM_IV: process.env.REACT_APP_ALGORITHM_IV,
  },
};
module.exports = env[process.env.REACT_APP_NODE_ENV];