export const DashboardRoute = {
  HOME: "/",
  LANDING_SECOND: "/landing2",
  LANDING_THIRD: "/landing3",
  DASHBOARD: "/dashboard",
};

export const CmsPageRoutes = {
  ABOUT_US: "/about-us",
  ABOUT_US_NEW: "/about-us-new",
  CONTACT_US: "/contact-us",
  FAQ: "/faq",
  NOTFOUND: "/404",
  CARRIER: '/career',
  PRICING: "/pricing",
  PRIVACY_POLICY: "/privacy-policy",
  SITEMAP: "/sitemap",
  ILIFE_QUOTE_ENGINE: "/ilife-quote-engine",
};
